class ChargerStatus {
    public static available = 'available';
    public static preparing = 'preparing';
    public static charging = 'charging';
    public static suspendedEVSE = 'suspendedEVSE';
    public static suspendedEV = 'suspendedEV';
    public static finishing = 'finishing';
    public static reserved = 'reserved';
    public static unavailable = 'unavailable';
    public static faulted = 'faulted';
    public static error = 'error';
    public static offline = 'offline';
    public static loggedOut = 'loggedOut';
    public static appError = 'appError';
    public static appOffline = 'appOffline';
    public static none = 'none';
}

// tslint:disable-next-line:max-classes-per-file
export class ChargingStation {

    public static getStatus(status: string) {
        switch (status) {
            case "Available":
                return ChargerStatus.available;
            case "Preparing":
                return ChargerStatus.preparing;
            case "Charging":
                return ChargerStatus.charging;
            case "SuspendedEVSE":
                return ChargerStatus.suspendedEVSE;
            case "SuspendedEV":
                return ChargerStatus.suspendedEV;
            case "Finishing":
                return ChargerStatus.finishing;
            case "Reserved":
                return ChargerStatus.reserved;
            case "Unavailable":
                return ChargerStatus.unavailable;
            case "Faulted":
                return ChargerStatus.faulted;
            case "Error":
                return ChargerStatus.error;
            case "Offline":
                return ChargerStatus.offline;
            case "LoggedOut":
                return ChargerStatus.loggedOut;
            case "AppError":
                return ChargerStatus.appError;
            case "AppOffline":
                return ChargerStatus.appOffline;
            default:
                return ChargerStatus.none;
        }
    }
    public accessToken: string;
    public id: string;
    public uniqueId: string;
    public serialNumber: string;
    public connectorId: number;
    public chargerModelId: number;
    public chargerModelName: string;
    public qrCode: string;
    public status: ChargerStatus;

    constructor(data: any) {
        this.accessToken = data.accessToken;
        this.id = data.chargingStationId;
        this.uniqueId = data.uniqueId;
        this.serialNumber = data.serialNumber;
        this.connectorId = data.connectorId;
        this.chargerModelId = data.chargerModelId;
        this.chargerModelName = data.chargerModelName;
        this.qrCode = data.qrCode;
        this.status = ChargerStatus.none;
    }
}
