<template>
    <v-container fluid>
        
        <h3><v-icon @click="goBackToPreviousPage">mdi-chevron-left</v-icon>Login In Charger</h3>
        <br>
        <v-card>
            <v-form @submit.prevent="submitForm" class="pa-5">                
                <v-text-field v-model="charge_code" label="Enter charger code" ></v-text-field>
                <v-btn block @click="goToScanQrCode"><v-icon >mdi-qrcode</v-icon>Scan the charger QR Code</v-btn>
                <v-text-field v-model="password" label="Password" ></v-text-field>  
                
            </v-form>
        </v-card>
        <v-row class="submit-btn-row">
            <v-col >
                <v-btn block class="submit-btn" color="primary" @click="submitForm">Submit</v-btn>
            </v-col>
        </v-row>
        <v-col cols="auto">
            <v-dialog
                transition="dialog-top-transition"
                width="100%"
                v-model="dialog.isActive"
            >
                
                <template>
                <v-card>
                    <v-toolbar color="primary" dark>
                        <v-toolbar-title class="flex-grow-1">{{ dialog.title }}</v-toolbar-title>
                        <v-btn icon @click="dialog.isActive = false">
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-text class="text-center">
                        <img width="158" height="189" class="center-svg" :src="dialog.imgSvg" alt="SVG" />
                        <br>
                        <div class="caption-size" v-if="dialog.success">Charger adding success</div>
                        <div class="caption-false" v-if="!dialog.success">
                            <div class="caption-size-false">Sorry, the charger QR Code or Password is incorrect.</div>
                            <br>
                            <div class="caption-size-false">Please retry or contact Star Charge or Authorised Reseller.</div>
                    
                        </div>
                        

                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn block @click="afterAddSuccess" color="primary" v-if="dialog.success">Done</v-btn>
                        <v-row justify="center" align="center" v-if="!dialog.success" cols="12" sm="6" md="6">
                            <v-col cols="6">
                                <v-btn block @click="dialog.isActive = false" >No</v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn block @click="afterAddFalse" color="primary" >Yes</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
                </template>
            </v-dialog>
        </v-col>
    </v-container>
    
</template>

<script>
import Vue from "vue";
import loginChargerSuccess from '@/assets/images/svg/loginChargerSuccess.svg';
import loginChargerWarning from '@/assets/images/svg/loginChargerWarning.svg';
import coreApiClient from "@/services/apis/coreApiClient";
import stateManager from "@common/services/stateManager";
import { ChargingStation } from "@/utils/chargingStation";
export default Vue.extend({
    data() {
      return {
        charge_code: '',
        password: '',
        dialog: {
            isActive: false,
            imgSvg: loginChargerSuccess,
            title: 'Notification',
            success: true,
        }
      };
    },
    methods: {
        async initialize() {
            this.$nextTick(() => {
                const charge_code_param = this.$route.query.charger_code
                if (charge_code_param) {
                    this.charge_code = charge_code_param;
                }
            });
        },
        afterAddFalse() {
            this.dialog.isActive = false;
            this.submitForm();
        },
        afterAddSuccess() {
            this.$router.push(`/app/my_charger/home_m`);
        },
        goBackToPreviousPage() {
            this.$router.push(`/app/my_charger/home_m`);
        },
        goToScanQrCode() {
            this.$router.push(`/app/my_charger/scanning_qr_code_m`);
        },
        showDialogSuccess() {
            this.dialog = {
                isActive: true,
                imgSvg: loginChargerSuccess,
                title: 'Notification',
                success: true,
            }
        },
        showDialogFail() {
            this.dialog = {
                isActive: true,
                imgSvg: loginChargerWarning,
                title: 'Warning',
                success: false,
            }
        },
        async submitForm() {
            const result = await coreApiClient.call("auth", "publicAuthorizeChargingStation", null, {
                qrCode: this.charge_code,
                password: this.password,
            });
            if (result.ok) {
                let objectCharger = stateManager.get("objCharger");
                const charger = new ChargingStation(result);
                charger.qrCode = this.charge_code;
                if (!objectCharger) {
                    objectCharger = {};
                }
                const key = charger.id + ":" + charger.connectorId

                if (Object.keys(objectCharger).indexOf(key) > -1) {
                    const charger = objectCharger[key]
                    charger.status = '';
                } else {
                    objectCharger[key] = charger;
                }
                stateManager.set("objCharger", objectCharger);
                stateManager.set(`accessTokenByScenario_${key}`, charger.accessToken);
                this.showDialogSuccess();
            } else {
                this.showDialogFail();
            }
        },
    },
    async created() {
        await this.initialize();
    },
  });
</script>
<style scoped>
  .h3 {
  margin-left: 8px; /* Khoảng cách giữa biểu tượng mũi tên và tiêu đề */
  display: inline;
}
.icon-title {
  display: inline-flex;
  align-items: center; /* Căn chỉnh các phần tử theo chiều dọc */
}


.submit-btn-row {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px; /* Khoảng cách từ lề dưới của màn hình */
  justify-content: space-around
}

.submit-btn {
  border: 1px solid; /* Thiết lập viền */
  border-radius: 4px; /* Định dạng các góc của nút */
}
.center-svg {
  display: block; /* Đảm bảo ảnh nằm trên một dòng */
  margin: 0 auto; /* Căn giữa theo chiều ngang */
}

.caption-size {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #333333;
}
.caption-false{

    text-align: left;
}
.caption-size-false {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 24px;
}
  </style>
